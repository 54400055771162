<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Clientes' }, { name: 'Listado' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Clientes</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CreateCliente' }" color="primary">
          <svg style="width:24px;height:24px; margin-right: 10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
          </svg>
          Agregar Cliente
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex md4 sm4> </v-flex>
              <v-flex v-if="clientes.length" md8 sm8 xs12>
                <v-text-field
                  v-model="searchCliente"
                  box
                  append-icon="search"
                  label="Buscar"
                  clearable
                  hide-details
                />
              </v-flex>
            </v-layout>
            <v-data-table
              :headers="[
                { text: 'ID' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Apellidos', value: 'apellidos' },
                { text: 'Email', value: 'email' },
                { text: 'Teléfono', value: 'telefono' },
                { text: 'Dirección', value: 'direccion.direccion' },
                { text: 'Ciudad', value: 'direccion.ciudad.nombre' },
                { text: 'Notas' },
                { text: 'Historial' },
                { text: 'Acciones' }
              ]"
              :items="clientes"
              :loading="loadingClientes"
              :search="searchCliente"
              :rows-per-page-items="[25, 50, 100]"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.id }}
                </td>
                <td class="px-3">
                  {{ props.item.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.apellidos }}
                </td>
                <td class="px-3">
                  {{ props.item.email }}
                </td>
                <td class="px-3">
                  {{ props.item.telefono }}
                </td>
                <td class="px-3">
                  {{ props.item.direccion ? props.item.direccion.direccion : '' }}
                </td>
                <td class="px-3">
                  {{ props.item.direccion ? props.item.direccion.ciudad.nombre : '' }}
                </td>
                <td class="px-3">
                  <v-tooltip right v-if="props.item.nota">
                    <template v-slot:activator="{ on }">
                      <v-chip color="blue darken-1" text-color="white" dark v-on="on" large style="padding:0px 12px;">
                        <v-avatar>
                          <v-icon>event_note</v-icon>
                        </v-avatar>
                        Ver notas
                      </v-chip>
                    </template>
                    <span style="font-size:16px;line-height:36px;">
                      {{ props.item.nota }}
                    </span>
                  </v-tooltip>
                </td>
                <td class="px-3">
                  <v-btn
                    class="ma-0"
                    @click="openModalDetailcliente(props.item)"
                    :loading="props.item.id === clienteId ? openCliente : false"
                    small
                    color="primary"
                  >
                    Ver historial
                  </v-btn>
                </td>
                <td class="px-3">
                  <v-btn
                    class="ma-0"
                    small
                    icon
                    flat
                    color="blue"
                    :to="{ name: 'detailCliente', params: { id: props.item.id } }"
                  >
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="userIsAdmin || userIsSupervisor"
                    class="ma-0"
                    @click="openModalDeleteCliente(props.item)"
                    small
                    icon
                    flat
                    color="error"
                  >
                    <v-icon small>
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDelete @onChange="getClientes" />
    <ModalDetail />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de Clientes" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/clientes/ModalDeleteCliente"),
    ModalDetail: () => import("@/views/clientes/Detail")
  },

  data() {
    return {
      searchCliente: "",
      clienteId: "",
      openCliente: false
    };
  },

  computed: {
    ...mapState({
      clientes: state => state.clientes.clientes,
      loadingClientes: state => state.clientes.loadingClientes
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      userIsAdmin: "auth/userIsAdmin",
      userIsSupervisor: "auth/userIsSupervisor"
    })
  },
  created() {
    this.getClientes();
  },
  methods: {
    ...mapActions({
      getClientes: "clientes/getClientes",
      replaceShowModalDeleteCliente: "clientes/replaceShowModalDeleteCliente",
      replaceCurrentCliente: "clientes/replaceCurrentCliente",
      replaceShowModalDetailCliente: "clientes/replaceShowModalDetailCliente",
      getStadistics: "clientes/getStadistics",
      getCars: "clientes/getCars",
      getCarsItems: "clientes/getCarsItems",
      getCliente: "clientes/getCliente"
    }),
    openModalDeleteCliente(cliente) {
      this.replaceCurrentCliente({ cliente });
      this.replaceShowModalDeleteCliente({ status: true });
    },
    async openModalDetailcliente(cliente) {
      this.clienteId = cliente.id;
      this.openCliente = true;
      await this.getStadistics({ clienteId: cliente.id });
      await this.getCars({ clienteId: cliente.id });
      await this.getCarsItems({ clienteId: cliente.id });
      await this.getCliente({ clienteId: cliente.id });
      this.openCliente = false;
      this.replaceShowModalDetailCliente({ status: true });
    }
  }
};
</script>
