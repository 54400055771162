var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.authenticated)?_c('NotPermission'):_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('Breadcrumbs',{attrs:{"routes":[{ name: 'Inicio' }, { name: 'Clientes' }, { name: 'Listado' }]}}),_c('v-card',[_c('v-toolbar',{attrs:{"color":"grey darken-1","dark":"","card":""}},[_c('v-toolbar-title',[_vm._v("Clientes")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'CreateCliente' },"color":"primary"}},[_c('svg',{staticStyle:{"width":"24px","height":"24px","margin-right":"10px"},attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"}})]),_vm._v(" Agregar Cliente ")])],1),_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md4":"","sm4":""}}),(_vm.clientes.length)?_c('v-flex',{attrs:{"md8":"","sm8":"","xs12":""}},[_c('v-text-field',{attrs:{"box":"","append-icon":"search","label":"Buscar","clearable":"","hide-details":""},model:{value:(_vm.searchCliente),callback:function ($$v) {_vm.searchCliente=$$v},expression:"searchCliente"}})],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
              { text: 'ID' },
              { text: 'Nombre', value: 'nombre' },
              { text: 'Apellidos', value: 'apellidos' },
              { text: 'Email', value: 'email' },
              { text: 'Teléfono', value: 'telefono' },
              { text: 'Dirección', value: 'direccion.direccion' },
              { text: 'Ciudad', value: 'direccion.ciudad.nombre' },
              { text: 'Notas' },
              { text: 'Historial' },
              { text: 'Acciones' }
            ],"items":_vm.clientes,"loading":_vm.loadingClientes,"search":_vm.searchCliente,"rows-per-page-items":[25, 50, 100]},scopedSlots:_vm._u([{key:"items",fn:function(props){return _c('tr',{},[_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.id)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.nombre)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.apellidos)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.email)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.telefono)+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.direccion ? props.item.direccion.direccion : '')+" ")]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(props.item.direccion ? props.item.direccion.ciudad.nombre : '')+" ")]),_c('td',{staticClass:"px-3"},[(props.item.nota)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-chip',_vm._g({staticStyle:{"padding":"0px 12px"},attrs:{"color":"blue darken-1","text-color":"white","dark":"","large":""}},on),[_c('v-avatar',[_c('v-icon',[_vm._v("event_note")])],1),_vm._v(" Ver notas ")],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"16px","line-height":"36px"}},[_vm._v(" "+_vm._s(props.item.nota)+" ")])]):_vm._e()],1),_c('td',{staticClass:"px-3"},[_c('v-btn',{staticClass:"ma-0",attrs:{"loading":props.item.id === _vm.clienteId ? _vm.openCliente : false,"small":"","color":"primary"},on:{"click":function($event){return _vm.openModalDetailcliente(props.item)}}},[_vm._v(" Ver historial ")])],1),_c('td',{staticClass:"px-3"},[_c('v-btn',{staticClass:"ma-0",attrs:{"small":"","icon":"","flat":"","color":"blue","to":{ name: 'detailCliente', params: { id: props.item.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1),(_vm.userIsAdmin || _vm.userIsSupervisor)?_c('v-btn',{staticClass:"ma-0",attrs:{"small":"","icon":"","flat":"","color":"error"},on:{"click":function($event){return _vm.openModalDeleteCliente(props.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1):_vm._e()],1)])}}])})],1)],1)],1)],1),_c('ModalDelete',{on:{"onChange":_vm.getClientes}}),_c('ModalDetail')],1)}
var staticRenderFns = []

export { render, staticRenderFns }